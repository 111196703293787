<template>
    <CForm ref="form" class="needs-validation"   >
        <CSelect
            label="Alınacak Stok Kanalı"
            horizontal
            placeholder="Seçiniz.."
            :options="stockChannelNames"
            :value.sync="data.fromPosId"
        />
        <CSelect
            label="Verilecek Stok Kanalı"
            horizontal
            placeholder="Seçiniz.."
            :options="stockChannelNames"
            :value.sync="data.toPosId"
        />
        <CInput
            label="Açıklama"
            type="text"
            autocomplete="text"
            v-model="data.description"
            horizontal
        >
        </CInput>
        <CRow form class="form-group">
            <CCol tag="label" sm="3" class="col-form-label">
                Hepsi yada Hiçbiri
            </CCol>
            <CCol sm="9">
                <input style="height: 20px; width: 20px" type="checkbox" v-model="data.allOrNone">
            </CCol>
        </CRow>
        <CTextarea
            label="SKU Kod ve Adet "
            horizontal
            type="text"
            autocomplete="text"
            rows="6"
            placeholder="SKU Kod ve Adet"
            v-model="data.skuCodeAmounts"
        >
        </CTextarea>
   </CForm>
</template>

<script>
   export default{
       name: "MoveLogSpecialForm",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create')
                   this.data = {}
           }
       },
       computed:{
            stockChannelNames: function() {
               let data = []
               this.$store.getters.activePos.map(r => data.push({value:r.id, label: r.name}))
               return data
            },
            activeProducts: function() {
               let data = []
               this.$store.getters.activeProducts.map(r => data.push({value:r.id, label: r.name}))
               return data
            },
            activeInventoryMoves: function(){
                let data = []
                this.$store.getters.activeInventoryMoves.map(r => data.push({value:r.id, label: r.name}))
                return data
            }
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
           }
       },
   }
</script>